import { render, staticRenderFns } from "./StoreOrder.vue?vue&type=template&id=378de224&scoped=true"
import script from "./StoreOrder.vue?vue&type=script&lang=js"
export * from "./StoreOrder.vue?vue&type=script&lang=js"
import style0 from "./StoreOrder.vue?vue&type=style&index=0&id=378de224&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "378de224",
  null
  
)

export default component.exports