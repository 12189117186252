<template>
  <div class="feedback w-100 my-2">
    <label style="font-size: 15px">
      <img
        style="width: 20px"
        :src="require('@/assets/images/app-icons/feedback@2x.png')"
      >
      {{ $t('Please provide your feedback') }}</label>
    <div class="feedback-wrapper w-100">
      <img
        class="w-50 mx-auto my-2 d-block"
        :src="require('@/assets/images/app-icons/undraw_personal_opinions_re_qw29.png')"
      >
    </div>
    <label
      v-if="!userRated"
      class="font-16 text-center d-block"
    >{{ $t('Order Rating: ') }}</label>
    <div
      v-if="!userRated"
      class="wrap-ratings d-flex justify-content-center my-1"
    >
      <img
        v-for="star in 5"
        :key="star"
        style="height: 25px"
        :class="{ 'gray-scale-icon': star > ratingPoint }"
        class="cursor-pointer"
        :src="require('@/assets/images/app-icons/rating.png')"
        @click="ratingPoint = star"
      >
    </div>
    <label v-if="!userRated">{{ $t('For order:') }}</label>
    <textarea
      v-if="!userRated"
      v-model="storeReviewText"
      :placeholder="$t('Post a comment')"
      class="form-control"
    />
    <label
      v-if="adminService === 'ORDER' && !deliveryRated"
      class="font-16 text-center d-block mt-1"
    >{{ $t('Delivery Rating: ') }}</label>
    <div
      v-if="adminService === 'ORDER' && !deliveryRated"
      class="wrap-ratings d-flex justify-content-center my-1"
    >
      <img
        v-for="star in 5"
        :key="star"
        style="height: 25px"
        :class="{ 'gray-scale-icon': star > deliveryRatingPoint }"
        class="cursor-pointer"
        :src="require('@/assets/images/app-icons/rating.png')"
        @click="deliveryRatingPoint = star"
      >
    </div>
    <label v-if="adminService === 'ORDER' && !deliveryRated">{{ $t('For delivery:') }}</label>
    <textarea
      v-if="adminService === 'ORDER' && !deliveryRated"
      v-model="storeDeliveryReviewText"
      :placeholder="$t('Post a comment')"
      class="form-control"
    />
    <div class="col-md-12 text-center mt-2">
      <button
        class="btn btn-primary"
        @click="postReview"
      >
        {{ $t('Post Comment') }}
      </button>
    </div>
  </div>
</template>

<script>
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '../users/useUser'

export default {
  props: {
    requestId: {
      type: [String, Number],
      required: true,
    },
    userRated: {
      type: [String, Number],
      default: '',
    },
    deliveryRated: {
      type: [String, Number],
      default: '',
    },
    adminService: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storeReviewText: '',
      ratingPoint: 1,
      deliveryRatingPoint: 1,
      storeDeliveryReviewText: '',
    }
  },
  methods: {
    postDeliveryFeedback() {
      if (!this.storeDeliveryReviewText) {
        return
      }

      const { postDeliveryOrderReview } = useUserUi()

      const formData = new FormData()

      formData.append('order_id', this.requestId)
      formData.append('review', this.storeDeliveryReviewText)
      formData.append('rate', this.deliveryRatingPoint)
      if (this.userRated) {
        showLoader()
      }
      postDeliveryOrderReview(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$emit('review-posted', true)
          } else {
            this.$emit('review-posted', false)
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.$emit('review-posted', false)
          showErrorNotification(this, error)
        })
    },
    postReview() {
      const formData = new FormData()
      if (this.adminService === 'ORDER') {
        formData.append('order_id', this.requestId)
      } else {
        formData.append('request_id', this.requestId)
      }
      formData.append('review', this.storeReviewText)
      formData.append('rate', this.ratingPoint)

      const { postOrderReview, postServiceReview } = useUserUi()
      let postReview = postOrderReview
      if (this.adminService !== 'ORDER') {
        postReview = postServiceReview
      }
      if (this.adminService === 'ORDER' && !this.deliveryRated) {
        this.postDeliveryFeedback()
      }
      if (this.userRated) {
        return
      }
      if (!this.storeReviewText) {
        return
      }
      showLoader()
      postReview(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$emit('review-posted', true)
          } else {
            this.$emit('review-posted', false)
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.$emit('review-posted', false)
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.feedback .gray-scale-icon {
  filter: grayscale(1);
}
</style>
